import React, { useEffect, useState } from "react";
import Moment from 'moment';
import { Button, Dropdown, Form, Modal, Spin } from 'antd';
import {DownOutlined} from '@ant-design/icons';
import Logo from '../assets/logo.svg';
import Facebook from '../assets/facebook.svg';
import Twitter from '../assets/twitter.svg';
import Google from '../assets/google.svg';
import LoginIcon from '../assets/login.svg';
import { PollData, firebaseConfig } from "./FirebaseOps";
// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


const Test = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [poll, setPoll] = useState(null);
    const [user, setUser] = useState(null);

    // this function gets the current poll

    
    const openLoginModal = () =>{
        setIsLoginModalOpen(true);
    }

    //this function is called when user submits poll answer
    const onFinish = (values) => {

        // we still need to submit user id along with the answer
        console.log('Success:', values);
    };

    firebase.auth().onAuthStateChanged((u) => {
        if (u) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/v8/firebase.User
          var uid = u.uid;
          setUser(u);
          // ...
        } else {
          // User is signed out
          // ...
          setUser(null);
        }
      });

    var email=user!=null?user.uid:"unidentified";
    var dropDownItems = [
        {
            key:'1',
            label:<span>test</span>
        },
        {
            key:'2',
            label:<span>Logout</span>
        }
    ];

    return <>
      <div className='content'>
        <div style={{
            height:'88px',
            width:'100%', 
            display:"flex", 
            flexDirection:"row", 
            padding:"0 40px", 
            boxSizing:"border-box"}}>
            <div className='logo'>
                <img src={Logo} style={{height:"32px", margin:"28px 0"}} alt='The Crypto Poll Logo' />
            </div>
            <div style={{flex:"1", textAlign:"center"}}></div>
            <div className='login-section'>
                
                    <img src={LoginIcon} className='login-image' /> <span >Login</span>
                
            </div>
            
        </div>

        <div className="result-ribbon">
            <div className='last-day-result'>
                LAST DAY'S <span className='last-day-result-highlight'>RESULT</span>
            </div>
            <div className='result-Text'>
                78%
            </div>
            <div className='choosen-text'>have choosen<br />
            <span className='choosen-text-highlight'>B Option</span> that was 'No'</div>
        </div>

        <div className='timer-ribbon'>
            <div className='timer-box'>
                <div className='timer-number'>8</div>
                <div className='timer-text'>Hours</div>
            </div>
            
            <div className='timer-box'>
                <div className='timer-number'>59</div>
                <div className='timer-text'>Minutes</div>
            </div>
            
            <div className='timer-box'>
                <div className='timer-number'>18</div>
                <div className='timer-text'>Seconds</div>
            </div>
        </div>

<Button onClick={openLoginModal}> test button</Button>
        {poll!==null?
        

        <div className='question-section'>
            <div className='question-box'>
                <div className='question-text'>
                    {poll.question}
                </div>
                
                <div className='option-section'>
                {poll.options.map((option, key)=>    
                    <div className='option-select' key={key}>
                        <span className='option-key'>{key+1}</span>
                        {option}
                    </div>
                )}
                </div>
            </div>
        </div>
    :
    <div className='question-section'>
            <div className='question-box'>
                <div className='question-text'>
                    Fetching latest poll details!
                </div>
                
                <div className='option-section'>
                <br/><br/><br/><Spin /><br/><br/><br/>
                </div>
            </div>
        </div>}
        <div className='footer'>
            <div className='social'>
                <a href='#'><img src={Facebook}/></a>
                <a href='#'><img src={Twitter} /></a>
                <a href='#'><img src={Google}/></a>
            </div>
            <div style={{flex:1}}></div>
            <div className='contact'>Contact us: <a href='mailto:myemail@email.com'>myemail@email.com</a></div>
        </div>
    </div>
    
    </>
    
}

export default Test;