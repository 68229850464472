import './userPopup.css';
import Gem from '../assets/gems.svg';
import { Logout } from '../utils/login';
import User from '../assets/user_dark.svg';
import Mail from '../assets/mail.svg';
import { useState } from 'react';
import { notification } from 'antd';

const UserPopup = (props) =>{
    
    return <>

        <div className="user-popup">
        <img src={User} />

        {props.displayName?props.displayName:'User Name'}
        <br/><br/>
        <div className='info-section'>
            <img src={Mail} />{props.email?props.email:'email'}
        </div>
        <div className='info-section'>
            <img src={Gem} />{props.gems?props.gems:'0'} Gems
        </div>

        <div className='button-logout' onClick={()=>{
            Logout();
            props.openNotification();
        }}>
            Logout
        </div>
    </div>
</>
}

export default UserPopup;