import axios from "axios";
import { setGemsDistributed } from "./FirebaseOps";

const remoteURL = 'https://srv420867.hstgr.cloud';
const slug = "lXio0mNCu1pGG3cgNXrXL9JkJ7aHKwvS";
const gemsToGive = 2;

export const createUser = (userID) =>{
    axios.post(remoteURL, 
    {
        slug: slug,
        user_id: userID,
        operation: "create"
    })
    .then(function(response){
        console.log(response.data);}
    )
    .catch(function (error) {
        console.log(error);
    });
}

export const getUserGems = (userID) => {
    // userID = "CRg8xH9ZjeMoGq0Mjug2lYYMANV2";
    return axios.post(remoteURL, 
        {
            slug: slug,
            user_id: userID,
            operation: "read"
        })
        .then(function(response){
            // console.log(response.data.gems);
            return response.data;
        })
        .catch(function (error) {
            // console.log(error);
        });
}

export const giveUsersGems = (userID, pollId, setPollData, allPollData) => {
    if(userID!==null)
    {
        axios.post(remoteURL, 
        {
            slug: slug,
            user_id: userID,
            operation: "add",
            gems: gemsToGive
        })
        .then(function(response){
            console.log(response.data);
            // call function to set the poll to have distributed gem
            setGemsDistributed(pollId, setPollData,allPollData);
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    // there were no users here, means it was a draw.
    // we need to still set poll to have distributed gem
    else
        setGemsDistributed(pollId, setPollData,allPollData);
}