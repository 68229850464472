import { Container } from "react-bootstrap";

const TermsOfUse = () => {
    return <Container style={{marginTop:"50px", paddingBottom:"50px"}}>
    <h1>Terms of Use for TheCryptoPoll.com</h1>
    <p><span style={{color:"#aaa"}}>Effective Date: 16/11/2023</span></p>
<p><span>Welcome to TheCryptoPoll.com (the &quot;Website&quot;). These Terms of Use govern your access to and use of the Website and the services provided by TheCryptoPoll.com. By accessing or using the Website, you agree to be bound by these Terms of Use.</span></p>
<h2>1. User Eligibility:</h2>
<p><span>&nbsp; &nbsp;- You must be at least 18 years old to use TheCryptoPoll.com. By using the Website, you confirm that you are of legal age.</span></p>
<h2>2. Poll Submissions:</h2>
<p><span>&nbsp; &nbsp;- TheCryptoPoll.com encourages users to submit polls and share their opinions. By submitting polls, you agree to provide accurate and truthful information.</span></p>
<h2>3. Rewards Program:</h2>
<p><span>&nbsp; &nbsp;- TheCryptoPoll.com may offer a rewards program for users who participate in poll submissions. The terms and conditions of the rewards program will be outlined separately and are subject to change anytime.We never guarantee about any profit/reward.</span></p>
<h2>4. Prohibited Conduct:</h2>
<p><span>&nbsp; &nbsp;- You agree not to engage in any conduct that violates applicable laws or regulations. Prohibited conduct includes, but is not limited to, unauthorised access to the Website, interfering with the functionality of the Website, and submitting false information.</span></p>
<h2>5. Intellectual Property:</h2>
<p><span>&nbsp; &nbsp;- The content, design, and intellectual property on TheCryptoPoll.com are owned or licensed by TheCryptoPoll.com. You may not use, reproduce, or distribute any content from the Website without prior written consent.</span></p>
<h2>6. Privacy Policy:</h2>
<p><span>&nbsp; &nbsp;- Your use of TheCryptoPoll.com is also governed by our Privacy Policy. Please review the Privacy Policy to understand how we collect, use, and protect your personal information.</span></p>
<h2>7. Termination:</h2>
<p><span>&nbsp; &nbsp;- TheCryptoPoll.com reserves the right to terminate or suspend your access to the Website at any time, without prior notice, for any reason, including if we believe you have violated these Terms of Use.</span></p>
<h2>8. Disclaimer of Warranties:</h2>
<p><span>&nbsp; &nbsp;- TheCryptoPoll.com is provided &quot;as is&quot; without any warranties, express or implied. We do not guarantee the accuracy, completeness, or reliability of information on the Website.</span></p>
<h2>9. Limitation of Liability:</h2>

<p><span>&nbsp; &nbsp;- In no event shall TheCryptoPoll.com be liable for any indirect, consequential, or incidental damages arising out of or in connection with your use of the Website.</span></p>

<h2>10. Changes to Terms of Use:</h2>

<p><span>&nbsp; &nbsp;- TheCryptoPoll.com reserves the right to modify these Terms of Use at any time. Changes will be effective upon posting the updated terms on the Website.</span></p>

<h2>11. Governing Law:</h2>

<p><span>&nbsp; &nbsp;- These Terms of Use are governed by and construed in accordance with the laws of England. Any disputes arising from or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in England.</span></p>

<h2>12. Contact Us:</h2>

<p><span>&nbsp; &nbsp;- If you have any questions or concerns regarding these Terms of Use, please contact us at&nbsp;</span><span>contact@thecryptopoll.com</span><span>.</span></p>

<p><span>By using TheCryptoPoll.com, you agree to abide by these Terms of Use. Please check this page periodically for updates.</span></p>


</Container>
}

export default TermsOfUse;