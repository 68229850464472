import Moment from 'moment';

// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import { Timestamp } from "firebase/firestore";
import "firebase/compat/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyADoR9_sh7xLKbWVt8jehcjM7s9ETDb8S4",
    authDomain: "thecryptopoll.com",
    projectId: "the-crypto-poll",
    storageBucket: "the-crypto-poll.appspot.com",
    messagingSenderId: "232186163747",
    appId: "1:232186163747:web:63753266f8c2b21a83f796",
    measurementId: "G-6QWPF5LDRC"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = firebase.firestore();


// gets current day's poll's data
export const PollData = (setPoll, setParticipated, userID)=>{
    //get dates of today
    var todayDate = Moment.utc().format('YYYY-MM-DD');
    var todayStart = new Date(Moment.utc(todayDate, 'YYYY-MM-DD').valueOf());
    var todayEnd = new Date(Moment.utc(todayDate+" 23:59", 'YYYY-MM-DD HH:mm').valueOf());
    // get post for today's post range
    // console.log(todayDate+" : "+todayStart.valueOf()+" : "+todayEnd.valueOf());
    return db.collection('polls').where('date','>=', Timestamp.fromDate(todayStart)).where('date','<=', Timestamp.fromDate(todayEnd)).limit(1).get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            var data = doc.data();
            data.id = doc.id;
            // setPoll(data);
            // console.log(data);
            
            if (userID!==null && participated!==null)
            {
                setParticipated(participated(data,userID));
            }
            setPoll(data);
        });
    })
    .catch((error) => {
        console.log("Error getting Polla data ", error);
    });
}

// function to update the data of a poll or to create a poll
export const UploadOption = (id, data) => {
    return db.collection('polls').doc(id).update(data);
}

// check if user already submitted poll
const participated = (poll,userID)=>
{
    var submitted = false;
    if(poll.submission != null)
    {
        poll.submission.map((option)=>{
            // console.log(userID);
            if(option.users.includes(userID))
                submitted = true;
        });
    }
    return submitted;
}

// this function returns array of winners of the poll passed to it, returns null if draw or no votes
export const findWinnersOfPoll = (doc) =>{
    // this function only calculates the winners of the poll and returns the array of users
    var draw = false; // in case of draw this will become true
    var winnerVotes = 0; // this stores how many votes did the winner got
    var winnerUsers = new Array();
    if(doc.data().submission!==undefined)
    {

        doc.data().submission.map((opt,key)=>{
            if(opt.total > winnerVotes)
            {
                // console.log(opt.total+", "+winnerVotes);
                winnerVotes = opt.total;
                winnerUsers = opt.users;
                draw = false;
            }
            else if(opt.total === winnerVotes && winnerVotes!=0)
            {
                draw = true;
            }
        });
        
        // now it was a draw or no votes were cast send null
        if(draw || winnerVotes === 0)
            return null
        // if votes were cast and there was no draw, return the winner users
            return winnerUsers;
    }
    // if there were no votes cast 
    else
        return null;
}

// function to get the last day's result
export const GetLastResult=(setter)=>{
    //get dates of yesterday
    var yesterdayDate = Moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
    var yesterdayStart = new Date(Moment.utc(yesterdayDate, 'YYYY-MM-DD').valueOf());
    var yesterdayEnd = new Date(Moment.utc(yesterdayDate+" 23:59", 'YYYY-MM-DD HH:mm').valueOf());
    db.collection('polls')
    .where('date','>=', Timestamp.fromDate(yesterdayStart))
    .where('date','<=', Timestamp.fromDate(yesterdayEnd))
    .limit(1).get()
    .then((qsnap)=>{
        qsnap.forEach((doc)=>
        {    
            // console.log(doc.data());
            var result = {
                percent:0,
                optionText:"",
                optionIndex:-1
            }
            var draw = false; // in case of draw this will become true
            // var votes = new Array(); // this stores which option got how many votes
            var winnerOptionIndex=-1; // this stores which option (index) got maximum votes
            var totalVotes = 0; // this stores how many total votes were cast
            var winnerVotes = 0; // this stores how many votes did the winner got
            var drawOptions = new Array(); // this will store the options which had draw
            if(doc.data().submission!==undefined)
                doc.data().submission.map((opt,key)=>{
                    totalVotes += opt.total;
                    if(opt.total > winnerVotes)
                    {
                        // console.log(opt.total+", "+winnerVotes);
                        winnerVotes = opt.total;
                        winnerOptionIndex = key;
                        draw = false;
                    }
                    else if(opt.total === winnerVotes && winnerVotes!=0)
                    {
                        draw = true;
                        if (drawOptions?.length === 0)
                        {
                            drawOptions.push(winnerOptionIndex);
                        }
                        drawOptions.push(key);
                        console.log("draw found "+JSON.stringify(drawOptions));
                    }
                    
                    // console.log(opt.total+", "+winnerVotes+", "+winnerOptionIndex);
                });
            result.percent = Math.round(winnerVotes*100/totalVotes);
            result.optionIndex = draw?-1:winnerOptionIndex;
            result.optionText = draw? 
            "options"+drawOptions.map((element,index) => {
                    return " "+(element+1);
            })
            : doc.data().options[winnerOptionIndex];
            // console.log(result.optionText);

            setter(result);
        });
    })
}

/* redundant function, we are doing this with SQL now
export const AddGems = (pollId) => {
    const GemsToAdd = 2; // this will add specific numbers of gems to the users
    const FInc = firebase.firestore.FieldValue.increment(GemsToAdd);

    // get details of the poll
    db.collection('polls').doc(pollId).get().then((doc)=>{
        if(doc.exists){
            // if doc exists check the win or draw
            var draw = false; // in case of draw this will become true
            var users; // this stores user list of the winning option
            var winnerVotes = 0; // this stores how many votes did the winner got
            if(doc.data().submission!==undefined)
            {
                doc.data().submission.map((opt,key)=>{
                    if(opt.total > winnerVotes)
                    {
                        // console.log(opt.total+", "+winnerVotes);
                        winnerVotes = opt.total;
                        users = opt.users;
                        draw = false;
                    }
                    else if(opt.total === winnerVotes && winnerVotes!=0)
                    {
                        draw = true;
                    }
                });
            }
            // console.log(winnerVotes+", "+JSON.stringify(users)); getting the users list
            // logic to increment the user gems
            // db.collection('users').do
        }
    });
} */

// admin function to get data of all the polls, if start, end is not provided, fetches latest 20 polls
export const GetAllPoll = (setPollData,start,limit) => {
    var date = new Date();
    start = start==null?Timestamp.fromDate(date):Timestamp.fromDate(start);
    limit = limit==null?10:limit;
    
    db.collection('polls').orderBy('date','desc').limit(20).get().then((qsnap)=>{
        
        // console.log("called get all poll "+quer);
        var data = new Array();
        qsnap.forEach((doc)=>{
            // console.log("doc Data: "+doc);
            data.push(doc);
        })
        
        setPollData(data);
    })
}

// this functions sets a poll's state to gems have been distributed
export const setGemsDistributed  = (pollID, setPollData, allPolls) => {
    var updating = UploadOption(pollID,{gemDistributed:true});
    /* updating.then(()=>{
        // refresh the poll list
        var data = new Array();
        allPolls.forEach((poll)=>{
            if (poll.id === pollID)
            {
                poll.data().gemDistributed = true;
                console.log(poll.data());
            }
            data.push(poll);
            
        })
        // console.log(data);
        setPollData(data);
    }); */
}