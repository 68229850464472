import './Login.css';
import Google from '../assets/google.svg';
// import Facebook from '../assets/facebook.svg';
import Twitter from '../assets/twitter.svg';
import { Login } from '../utils/login';

const LoginPage = ()=>{

    return <div className='login-page'>
        <h1> Sign in with</h1>
        <div className='login-icons'>
            {/* <div className='login-icon-img' onClick={()=> Login("facebook")}><img src={Facebook}/>Facebook</div> */}
            <div className='login-icon-img' onClick={()=> Login("twitter")}><img src={Twitter}/>Twitter</div>
            <div className='login-icon-img' onClick={()=> Login("google")}><img src={Google}/>Google</div>
        </div>
        </div>

}

export default LoginPage;