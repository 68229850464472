import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
    return <Container>
    <h1 style={{marginTop:"50px"}}>Privacy Policy for TheCryptoPoll.com</h1>
<p><span style={{color:"#aaa"}}>Effective Date: 16/11/2023</span></p>
<p>Thank you for visiting TheCryptoPoll.com (the &quot;Website&quot;). Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website and participate in our poll submission rewards program.</p>
<h2>1. Information We Collect:</h2>
<h3>a. Personal Information:</h3>
<p><span >&nbsp; &nbsp;- When you register on our website or participate in our poll submissions, we may collect personal information such as your name, email address, and demographic details.</span></p>
<h3>b. Poll Submissions:</h3>
<p><span >&nbsp; &nbsp;- We collect data related to the polls you submit, including your responses and opinions.</span></p>
<h3>c. Device and Usage Information:</h3>
<p><span >&nbsp; &nbsp;- We may automatically collect information about your device, browser, IP address, and the pages you visit on our website.</span></p>
<h2>2. How We Use Your Information:</h2>
<h3>a. Poll Participation:</h3>
<p><span >&nbsp; &nbsp;- Your poll submissions are used to gather opinions and insights for research purposes.</span></p>
<h3>b. Communication:</h3>
<p><span >&nbsp; &nbsp;- We may use your email address to send you updates, newsletters, or information related to the website. You can opt-out of these communications at any time.</span></p>
<h3>c. Rewards Program:</h3>
<p><span >&nbsp; &nbsp;- If applicable, we may use your information to administer and </span><span>fulfil</span><span >&nbsp;rewards associated with poll participation.</span></p>
<h3>d. Improving User Experience:</h3>
<p><span >&nbsp; &nbsp;- We analyse data to enhance and personalise your experience on our website.</span></p>
<h2>3. Data Security:</h2>
<p><span >&nbsp; &nbsp;- We implement reasonable security measures to protect your data from unauthorised access, alteration, disclosure, or destruction.</span></p>
<h2>4. Data Sharing:</h2>
<p><span >&nbsp; &nbsp;- We do not sell, trade, or otherwise transfer your personally identifiable information to third parties without your consent. However, aggregated and anonymised data may be shared for research or promotional purposes.</span></p>
<h2>5. Cookies:</h2>
<p><span >&nbsp; &nbsp;- We use cookies to enhance your browsing experience and collect information about how you use our website. You can manage your cookie preferences through your browser settings.</span></p>
<h2>6. Third-Party Links:</h2>
<p><span >&nbsp; &nbsp;- Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. Please review the privacy policies of these third parties.</span></p>
<h2>7. Children&apos;s Privacy:</h2>
<p><span >&nbsp; &nbsp;- TheCryptoPoll.com is not directed at individuals under the age of 18. We do not knowingly collect personal information from children.</span></p>
<h2>8. Changes to Privacy Policy:</h2>
<p><span >&nbsp; &nbsp;- We reserve the right to modify this Privacy Policy at any time. Changes will be effective upon posting the updated policy on the website.</span></p>
<h2>9. Your Choices:</h2>
<p><span >&nbsp; &nbsp;- You have the right to access, correct, or delete your personal information. If you have any concerns or questions, please contact us at&nbsp;</span><span >contact@thecryptopoll.com</span><span >.</span></p>
<h2>10. Contact Us:</h2>
<p><span >&nbsp; &nbsp;- If you have any questions or concerns regarding this Privacy Policy, please contact us at&nbsp;</span><span>contact@thecryptopoll.com</span><span >.</span></p>
<p><br /></p>
<p><span style={{paddingBottom:"50px"}}>By using TheCryptoPoll.com, you agree to the terms outlined in this Privacy Policy. Please check this page periodically for updates.</span></p>

</Container>
}

export default PrivacyPolicy;