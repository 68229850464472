import { Button, Col, Modal, Popover, Row, Spin, notification } from "antd";
import { useEffect, useState } from "react";
//firebase
import { GetLastResult, PollData, UploadOption, firebaseConfig } from "./FirebaseOps";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// images
import Logo from '../assets/logo.svg';
import Twitter from '../assets/twitter.svg';
import LoginIcon from '../assets/login.svg';
import Success from '../assets/success.svg';
import Quora from '../assets/quora.svg';
import Reddit from '../assets/reddit.svg';
import Instagram from '../assets/instagram.svg';

import LoginPage from './LoginPage';
import UserPopup from "./UserPopup";
import { CaretDownOutlined } from "@ant-design/icons";
import Moment from "moment";
import { Auth } from "../utils/login";
import { getUserGems } from "./sqlOps";


const Home = ()=>{
    const [modalOpen, setModalOpen] = useState(false);
    const [poll, setPoll] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [userData, setUserData] = useState(null);
    const [countDown , setCountDown] = useState(new Date(0));
    const [result, setResult] = useState(null);
    const [submitted, setSubmitted] = useState(false); // default should be true to disable submitting without question load
    const [showSelectWarning, setShowSelectWarning] = useState(false);
    const [userParticipated, setUserParticipated] = useState(false); // this decides if user already participated in poll and if the poll should be visible!
    const [lastResult, setLastResult] = useState(null)
    const [api, contextHolder] = notification.useNotification();
    const [userGems, setUserGems] = useState("Fetching...");
    
    const openNotification = () => {
        api.info({
        message: 'Signed out',
        description: 'You have been successfully signed out of The crypto poll. You will have to Sign In again in order to participate in polls!',
        });
    };

    const popupContent = userData?<UserPopup gems={userGems} 
        displayName={userData.displayName}
        email={userData.email}
        openNotification={openNotification}/>:'';
    

    const submitPoll = () => {
        // check if options were selected
        if(selectedOption === null)
        {
            setShowSelectWarning(true);
            return;
        }

        // now disable the button
        setSubmitted(true);

        // now get user ID and submit it along the poll option
        var uid = userData.uid;
        var localPoll = poll;
        // if this is the first entry, creae a model
        if(localPoll.submission == null)
        {
            localPoll.submission = new Array();
            for(var i=0; i<localPoll.options.length; i++)
            {
                localPoll.submission.push({
                    total:0,
                    users: new Array()
                });
            }
        }

        // fill in entries
        var data = localPoll.submission[selectedOption];
        data.total++;
        data.users.push(uid);
        localPoll.submission[selectedOption] = data;
        setPoll(poll);
        var pollId = localPoll.id;
        delete localPoll.id;
        // console.log(localPoll);

        // now upload this data to firebase
        UploadOption(pollId, localPoll);
        
        // avoid this to give success message setUserParticipated(true);
    }


    useEffect(()=>{
        GetLastResult(setLastResult);
        const auth = getAuth();
        const unsubscribe = Auth.onAuthStateChanged((user) => {
            // console.log("onAuthStateChanged called: "+user);
            if (user) {
                setUserData(user);
                // get poll data
                PollData(setPoll,setUserParticipated,user.uid);
                // get user gems
                getUserGems(user.uid).then((result)=>{
                    if(result.status===1)setUserGems(result.gems);
                })
            } else {
                // console.log("user not found");
                setUserData(null);
                PollData(setPoll,null,null);
            }
          });

          
        
          // this part calculates the countdown
          setInterval(() => {
            var todayEnd = new Date(Moment.utc(Moment.utc().format('YYYY-MM-DD')+" 23:59", 'YYYY-MM-DD HH:mm').valueOf());
            var dateRightNow = new Date(Moment.utc().valueOf());
            var timeDiff = new Date(todayEnd.getTime()-dateRightNow.getTime());
            // console.log(Math.ceil(timeDiff.getTime()/1000));
            setCountDown(Math.trunc(timeDiff.getTime()/1000));
        }, 1000);
        return unsubscribe;
    },[])
    
    return <>
    {contextHolder}
    <div className='content'>
      <div className="header-section">
        <div className='logo'>
            <img src={Logo} style={{height:"32px", margin:"28px 0"}} alt='The Crypto Poll Logo' />
        </div>
        <div style={{flex:"1", textAlign:"center"}}></div>
        {
        userData==null?
            <div className='login-section' onClick={()=>{setModalOpen(true)}}>
                    <div className="login-image-section"><img src={LoginIcon} className='login-image' /></div> <span >Sign In</span>
            </div>:
            <Popover placement="bottomRight" trigger="click" content={popupContent}>
                <div className="login-section">
                    <img src={LoginIcon} className='login-image' /> 
                    <span >{userData.displayName.split(" ")[0]}&nbsp;<CaretDownOutlined /></span>
                </div>
            </Popover>  
        }  
      </div>

      <div className="result-ribbon">
          <div className='last-day-result'>
              LAST DAY'S <span className='last-day-result-highlight'>RESULT</span>
          </div>
          {lastResult && lastResult.optionIndex==-1?
          // this is the section which renders for a draw
          <div className='choosen-text'>It was a&nbsp;
          <span className='choosen-text-highlight'>Draw!</span>
          <br />Between {lastResult && lastResult.optionText?lastResult.optionText:" "}
          </div>
            :
          <><div className='result-Text'>
              {lastResult && lastResult.percent?lastResult.percent:'0'}%
          </div>
          <div className='choosen-text'>Have choosen&nbsp;
          <span className='choosen-text-highlight'>Option {lastResult && lastResult.optionIndex!=-1?lastResult.optionIndex+1:'-'}</span>,
          <br />that was '{lastResult && lastResult.optionText?lastResult.optionText:" "}'</div>
          </>}
      </div>

      <div className='timer-ribbon'>
          <div className='timer-box'>
              <div className='timer-number'>{Math.trunc((countDown/3600)%24)}</div>
              <div className='timer-text'>Hours</div>
          </div>
          
          <div className='timer-box'>
              <div className='timer-number'>{Math.trunc((countDown/60)%60)}</div>
              <div className='timer-text'>Minutes</div>
          </div>
          
          <div className='timer-box'>
              <div className='timer-number'>{countDown%60}</div>
              <div className='timer-text'>Seconds</div>
          </div>
      </div>
      {poll!==null?
        
        
        <div className='question-section'>
            {userParticipated?
            <div className="already-submitted">
                <div>You have already participated.</div>
                <div>Please come back tomorrow!</div>
            </div>:
            submitted?
            <div className="already-submitted">
                <div><img src={Success}/></div>
                <div>We appreciate your opinion!</div>
            </div>:
            <div className='question-box'>
                <div className='question-text'>
                    {poll.question}
                </div>
                
                <Row className='option-section' >
                {poll.options.map((option, key)=>    
                    <Col key={key} sm={24} md={12} lg={12} className="option-select-col"
                    onClick={()=>setSelectedOption(key)}>
                        <div className={selectedOption==key?'option-select selected':'option-select'}>
                            <span className='option-key'>{key+1}</span>
                            {option}
                        </div>
                    </Col>
                )}
                {userData?
                <Col span={24} className="submit-section"><div className={submitted?"submit-button disabled":"submit-button"} 
                onClick={submitPoll}>Submit</div></Col>:
                <><Col span={24} className="submit-section"><div className="submit-button" onClick={()=>{setModalOpen(true)}}>Sign In</div></Col><Col className="signin-info" span={24}>Sign in to poll and win reward!</Col></>
                }
                </Row>
            </div>            
            }           
        </div>
        
    :  
        <div className='question-section'>
            <div className='question-box'>
                <div className='question-text'>
                    Fetching latest poll details!
                </div>
                
                <div className='option-section'>
                <br/><br/><br/><Spin /><br/><br/><br/>
                </div>
            </div>
        </div>
    }
      <div className='footer'>
          <div className='social'>
              <a href='https://twitter.com/tcp24x7' target="_blank"><img src={Twitter} /></a>
              {/* <a href='https://tcp24x7.quora.com/' target="_blank"><img src={Quora} /></a>*/}
              <a href='https://www.instagram.com/tcp24x7/' target="_blank"><img src={Instagram} /></a>
              <a href='https://www.reddit.com/r/tcp24x7/' target="_blank"><img src={Reddit} /></a>
          </div>
          <div style={{flex:1}}></div>
          <div className='contact'>Contact us: <a href='mailto:contact@thecryptopoll.com'>contact@thecryptopoll.com</a></div>
      </div>
      <div className="footer-bot" style={{textAlign:"center", borderTop:'1px solid #ddd', boxSizing:"border-box", padding:'5px 10px', lineHeight:'25px'}}>
        The Crypto Poll, All rights reserved&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='./privacy-policy' target="_blank">Privacy Policy</a>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='./terms-of-use' target="_blank">Terms of Use</a>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='./data-deletion' target="_blank">Data Deletion Policy</a>
      </div>
  </div>
  <Modal 
  open={modalOpen}
  centered
  onCancel={()=>{setModalOpen(false)}}
  footer={null}
  width={900}>

    <LoginPage />
  </Modal>
  </>
}

export default Home;