// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './mobile.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/Home';
import Admin from './Pages/Admin';
import Test from './Pages/test';
import PrivacyPolicy from './Pages/privacy-policy';
import DataDeletion from './Pages/dataDeletion';
import TermsOfUse from './Pages/TermsOfUse';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/test" element={<Test />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/data-deletion" element={<DataDeletion />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
