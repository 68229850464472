import React from 'react';
// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { firebaseConfig } from '../Pages/FirebaseOps';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const Auth = firebase.auth();
var google = new firebase.auth.GoogleAuthProvider();
var facebook = new firebase.auth.FacebookAuthProvider();
var twitter = new firebase.auth.TwitterAuthProvider();

export const Login = (social)=> {
  var provider = "";
  if(social === "google")
  {
    provider = google;
  }
  else if(social === "facebook")
  {
    provider=facebook;
  }
  else if(social === "twitter")
  {
    provider = twitter
  }
  
  Auth
    .signInWithRedirect(provider)
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      console.log(errorCode,errorMessage,email,credential);
      // ...
    });
}

export const Logout = ()=>{
    Auth.signOut().then(()=>{
    });
}