import { Container } from "react-bootstrap";

const DataDeletion = () => {
    return <Container style={{marginTop:"50px", paddingBottom:"50px"}} >
    <p><span >At &quot;The Crypto Poll,&quot; we prioritise the security and privacy of our users. This data deletion policy outlines how we handle user information to ensure transparency and compliance with privacy standards.</span></p>
<h2>1. Data Collection:</h2>
<ul>
    <li >
        <p><span  >We collect minimal user information during the registration process, including a unique identifier, username, and an encrypted password.</span></p>
    </li>
    <li >
        <p><span  >When users participate in polls, we record their selected choices and associated preferences.</span></p>
    </li>
    <li >
        <p><span  >We do not store sensitive personal information such as financial details, addresses, or any information unrelated to the polling process.</span></p>
    </li>
</ul>
<h2>2. Retention Period:</h2>
<ul>
    <li >
        <p><span  >User participation data, including poll responses, is retained for a period of 30 days.</span></p>
    </li>
    <li >
        <p><span  >After this period, all personally identifiable information is anonymised, and only aggregated, non-identifiable data is retained for analytical purposes.</span></p>
    </li>
</ul>
<h2>3. Deletion Process:</h2>
<ul>
    <li >
        <p><span  >Users have the right to request the deletion of their account and associated data at any time.</span></p>
    </li>
    <li >
        <p><span  >Account deletion can be initiated through the user profile settings on the website.</span></p>
    </li>
    <li >
        <p><span  >Poll participation data tied to a specific user is anonymised after 30 days and cannot be linked back to the individual.</span></p>
    </li>
</ul>
<h2>4. Web3.0 Integration:</h2>
<ul>
    <li >
        <p><span  >&quot;The Crypto Poll&quot; leverages Web3.0 technology for secure and decentralised interactions.</span></p>
    </li>
    <li >
        <p><span  >Blockchain technology ensures the integrity of poll results and the transparent conversion of earned points into tokens.</span></p>
    </li>
</ul>
<h2>5. Rewards and Tokens:</h2>
<ul>
    <li >
        <p><span  >Users earn points based on the majority selection of their poll answers.</span></p>
    </li>
    <li >
        <p><span  >Points can be converted into tokens through a secure and transparent process facilitated by our Web3.0 integration.</span></p>
    </li>
    <li >
        <p><span  >Token transactions are recorded on the blockchain, providing an immutable and auditable trail.</span></p>
    </li>
</ul>
<h2>6. Security Measures:</h2>
<ul>
    <li >
        <p><span  >We employ industry-standard encryption protocols to protect user data during transmission and storage.</span></p>
    </li>
    <li >
        <p><span  >Regular security audits are conducted to identify and address potential vulnerabilities.</span></p>
    </li>
</ul>
<h2>7. Compliance:</h2>
<ul>
    <li >
        <p><span  >&quot;The Crypto Poll&quot; adheres to relevant data protection regulations and strives to exceed industry standards for user privacy and security.</span></p>
    </li>
</ul>
<h2>8. Communication:</h2>
<ul>
    <li >
        <p><span  >Users will be informed of any material changes to the data deletion policy through website notifications or direct communication channels.</span></p>
    </li>
</ul>
<br/>
<p><span  >By using &quot;The Crypto Poll,&quot; users acknowledge and agree to the terms outlined in this data deletion policy. Our commitment is to provide a secure and rewarding polling experience while respecting user privacy.</span></p>

</Container>
}

export default DataDeletion;