import React, { useEffect, useState } from 'react';
import { Col, Row, Menu, Button, Space, Form, Input, DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'

// Import the functions you need from the SDKs you need
import { Timestamp } from "firebase/firestore";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import { AddGems, GetAllPoll, findWinnersOfPoll, firebaseConfig } from './FirebaseOps';
import moment from 'moment';
import { createUser, getUserGems, giveUsersGems } from './sqlOps';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = firebase.firestore();

const Admin = () => {
    const [mainContent, setMainContent] = useState('create-poll')
    const [allPolls, setAllPolls] = useState();

    useEffect(()=>{
        // this will fetch all the polls
        GetAllPoll(setAllPolls);
    },[])

    const Primarymenu = [
        {
            label:'Create Poll',
            key:'create-poll'
        },
        {
            label:'Question List',
            key:'question-list',
        },
        {
            label:'Press Result',
            key:'press-result',
        },
        {
            label:'Time',
            key:'time',
        },
        {
            label:'Social Media',
            key:'social-media',
        }
    
    ]

    const menuClick = (e) => {
        setMainContent(e.key);
    }

    const onFinish = (values) => {
        // set the value in the database
        if (values.date !== null)
        {
            values.date = new Date(dayjs(values.date).unix()*1000);
            values.date = Timestamp.fromDate(values.date);
        }
        db.collection("polls").add(values)
        .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
        });
        console.log('Success:', values);
      };

    
    const Content = () => {
        if(mainContent === 'create-poll')
            return <CreatePoll />;
        else if(mainContent === 'question-list')
            return <PollList />;
        else
            return <div>nothing selected</div>;
    }

    const CreatePoll = ()=>{
        // show a form with increasing fields
        return <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                label="Question"
                name="question"
                rules={[{ required: true, message: 'Please provide a question!' }]}
                >
                <Input />
            </Form.Item>

            <Form.List name="options">
                {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
             <Space
             key={key}
             style={{
               display:'flex',
               marginBottom: 8,
             }}
             align="baseline"
           >
              <Form.Item
                {...restField}
                label={"Option "+(name+1)}
                name={[name]}
                rules={[
                  {
                    required: true,
                    message: 'Please provide option ',
                  },
                ]}
                wrapperCol={{ offset: 8, span: 16 }}
              >
                <Input/>
              </Form.Item>
              
              <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
          ))}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Options
            </Button>
          </Form.Item>
        </>
      )}
            </Form.List>
            
            <Form.Item
            name={'date'}
            label={'Date'}
            rules={[{ required: true, message: 'Please provide a date!' }]}>
                <DatePicker format={'DD/MM/YYYY'} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    }

    const PollList = () => {
        // here we just have to iterate through all the polls that have been fetched
        return allPolls!=null?<div style={{boxSizing:"border-box", padding:"10px"}}>
        <div>Poll List</div>
        <table width={"100%"}>
            <thead>
            <tr>
                <th style={{padding:"5px"}}>#</th>
                <th style={{padding:"5px"}}>ID</th>
                <th style={{padding:"5px"}}>Question</th>
                <th style={{padding:"5px"}}>Options</th>
                <th style={{padding:"5px"}}>Schedule</th>
                <th style={{padding:"5px"}}>Actions</th>
            </tr></thead>
            <tbody>
        {allPolls.map((poll, key)=>{
            // console.log('1: '+poll.data());
            return <tr key={key} style={{ borderTop:"1px solid #eee"}}>
                <td style={{padding:"5px"}}>{key+1}</td>
                <td style={{padding:"5px", width:"200px"}}>{poll.id}</td>
                <td style={{padding:"5px", width:"50%"}}>{poll.data().question}</td>
                <td style={{padding:"5px"}}>{poll.data().options.length}</td>
                <td style={{padding:"5px"}}>{moment( poll.data().date.toDate()).format("DD-MM-YYYY")}</td>
                <td style={{padding:"5px"}}>{
                    !poll.data().gemDistributed? 
                        <span onClick={()=>giveUsersGems(findWinnersOfPoll(poll), poll.id, setAllPolls, allPolls)}>Dist Gem</span>:
                        <span>NA</span>}</td>
            </tr>
        })}
        </tbody>
        </table>
        </div>:<div>No poll data found</div>
        
    }


    return(<div>
        <Row style={{width:"100%", height:"100%"}}>
            <Col span={3}>
                <Menu
                    onClick={menuClick}
                    defaultSelectedKeys={['create-poll']}
                    mode="inline"
                    items={Primarymenu}
                />
            </Col>
            <Col span={21}>
                <Content/>
            </Col>
        </Row>
    </div>);
}


export default Admin;